export default [
  {
    title: 'Tollfree Voice',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    tagVariant: 'light-warning',
    action: 'client',
    resource: 'ACL',
    children: [
      {
        title: 'Setting',
        route: 'call-Setting',
        action: 'client',
        resource: 'ACL',
        icon: 'SettingsIcon',
      },
      {
        title: 'Hacs Integration',
        route: 'call-HacsIntegration',
        action: 'client',
        resource: 'ACL',
        icon: 'SettingsIcon',
      },
      {
        title: 'Call Node-Red',
        route: 'call-NodeRed',
        action: 'client',
        resource: 'ACL',
        icon: 'SettingsIcon',
      },
      {
        title: 'Call Automation',
        route: 'call-Automation',
        action: 'client',
        resource: 'ACL',
        icon: 'SettingsIcon',
      },
    ]

  }
]
