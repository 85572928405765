export default [

  {
    title: 'Packages',
    icon: 'PackageIcon',
    tagVariant: 'light-warning',
    tagVariant: 'light-warning',
    action: 'client',
    resource: 'ACL',
    children: [
      {
        title: 'Buy Package',
        route: 'package-list',
        action: 'client',
        resource: 'ACL',
        icon: 'PackageIcon',
      },
      {
        title: 'Package History',
        route: 'package-history',
        action: 'client',
        resource: 'ACL',
        icon: 'PackageIcon',
      },

      // {
      //   title: 'Package List Order',
      //   route: 'package-order-list',
      // },

    ]

  }
]
