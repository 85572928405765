export default [
  {
    header: 'Administrators Manage',
  },
  {
    title: 'Admin Manage',
    icon: 'DatabaseIcon',
    tagVariant: 'light-warning',
    tagVariant: 'light-warning',
    children: [

      {
        title: 'Service Rate',
        route: 'service-rate',
        icon: 'HomeIcon',
      },
      {
        title: 'Package',
        route: 'package-manage',
        icon: 'PackageIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'UsersIcon',
      },
      {
        title: 'Manage Hacs Integration',
        route: 'Manage-Hacs-Integration',
        icon: 'UsersIcon',
      },
      {
        title: 'Manage Call Automation',
        route: 'Manage-Call-Automation',
        icon: 'UsersIcon',
      },
      {
        title: 'Manage Call Node Red',
        route: 'Manage-CallขNode-Red',
        icon: 'UsersIcon',
      },
      {
        title: 'TH SMS Log',
        route: 'log-thSMS',

        icon: 'DatabaseIcon',
      },

    ]
  }
]


