export default [

  {
    title: 'Dashboards',
    route: 'dashboards',
    icon: 'HomeIcon',
    // action: 'client',
    // resource: 'ACL',

  },
]
