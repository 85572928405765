export default [
  {
    title: 'Log Datas',
    icon: 'DatabaseIcon',
    tagVariant: 'light-warning',
    tagVariant: 'light-warning',
    action: 'client',
    resource: 'ACL',
    children: [
      {
        title: 'Toll Log',
        route: 'log-toll',
        action: 'client',
        resource: 'ACL',
        icon: 'DatabaseIcon',
      },
      {
        title: 'Credit Log',
        route: 'log-credit',
        action: 'client',
        resource: 'ACL',
        icon: 'DatabaseIcon',
      },

    ]

  }
]
