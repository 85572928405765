
import dashboard from './dashboards'
import tollFreeVoices from './tollFreeVoices'
import packages from './package'
import Logs from './Logs'

import Admins from './Admins'

export default [
  ...Admins,
  {
    header: 'Menus',
  },

  ...dashboard,
  ...tollFreeVoices,
  ...packages,
  ...Logs,
]
